




































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: false }) notEditable
  @Prop({
    default: () => {
      return {}
    },
  })
  controller

  preventNegativeNumber(event) {
    if ((event.charCode != 8 && event.charCode == 0) || (event.charCode >= 48 && event.charCode <= 57)) return true
    else event.preventDefault()
  }

  get heightInput() {
    return this.$vuetify.breakpoint.smOnly ? 44 : 50
  }
  get bgInput() {
    return this.$vuetify.breakpoint.smOnly ? 'primary darken-1' : 'primary darken-2'
  }
}
